<template>
  <v-container>
    <v-row>
      <v-col class="mb-4 text-center">
        <p>Confiar siempre será mejor que no confiar.</p>

        <v-btn rounded color="primary" x-large class="black--text px-10" @click="$router.push('/')"><b>VER DE NUEVO</b></v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Final",
  created() {
    this.$emit("setImageBg", {});
  }
};
</script>
